var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("e-sidebar", {
        attrs: {
          title: _vm.edit ? _vm.$t("Editar Linha") : _vm.$t("Nova Linha"),
          show: _vm.showSidebar,
          fetching: _vm.fetching,
          saving: _vm.saving,
          width: "500px",
        },
        on: { save: _vm.saveLine, hidden: _vm.hideSidebar },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "FormulateForm",
                      { ref: "lineForm", attrs: { name: "form" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required input-uppercase",
                          attrs: {
                            id: "product-line-name",
                            type: "text",
                            label: _vm.$t("Nome"),
                            validation: "required",
                          },
                          model: {
                            value: _vm.localLine.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.localLine, "name", $$v)
                            },
                            expression: "localLine.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }