<template>
  <div>
    <e-sidebar
      :title="edit ? $t('Editar Linha') : $t('Nova Linha')"
      :show="showSidebar"
      :fetching="fetching"
      :saving="saving"
      width="500px"
      @save="saveLine"
      @hidden="hideSidebar"
    >
      <template #content>
        <div>
          <FormulateForm
            ref="lineForm"
            name="form"
          >
            <FormulateInput
              id="product-line-name"
              v-model="localLine.name"
              type="text"
              class="required input-uppercase"
              :label="$t('Nome')"
              validation="required"
            />
          </FormulateForm>
        </div>
      </template>
    </e-sidebar>
  </div>
</template>

<script>
import { ESidebar } from '@/views/components'
import { mapActions } from 'vuex'

const getInitialLocalLine = () => ({
  id: null,
  name: null,
  active: null,
})

export default {
  components: {
    ESidebar,
  },

  mixins: [],

  props: {
    lineId: {
      type: Number,
      default: null,
    },
    line: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      showSidebar: false,
      fetching: false,
      edit: false,
      saving: false,
      localLineId: null,
      localLine: getInitialLocalLine(),
    }
  },

  watch: {
    line(val) {
      this.localLine = val
    },
    lineId(val) {
      this.localLineId = val
    },
    async localLineId(val) {
      if (val) {
        try {
          this.fetching = true
          const { data: localLine } = await this.$http.get(`/api/lines/${this.localLineId}`)
          this.localLine = localLine
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      } else {
        this.localLine = {}
      }
    },
  },

  methods: {
    ...mapActions('pages/catalog/products', { comboFetchDomainData: 'fetchDomainData' }),

    showCreate() {
      this.localLine = getInitialLocalLine()
      this.edit = false
      this.showSidebar = true
    },

    async show({ id, item } = {}) {
      if (!id && !item?.id) {
        this.showCreate()
      } else {
        if (id) this.localLineId = id
        if (item) this.localLine = item
        this.edit = true
        this.showSidebar = true
      }
    },

    hideSidebar() {
      this.localLineId = null
      this.localLine = getInitialLocalLine()
      this.showSidebar = false
    },

    async saveLine() {
      this.$refs.lineForm.showErrors()
      if (this.$refs.lineForm.isValid) {
        try {
          this.saving = true

          const body = {
            id: this.localLine.id,
            name: this.localLine.name,
            active: this.localLine.active,
          }
          const result = await this.$http({
            url: '/api/lines',
            method: this.edit ? 'PUT' : 'POST',
            data: body,
          })
          this.comboFetchDomainData('lines')
          this.showSuccess({
            message: this.$t(`${this.edit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
          })
          this.showSidebar = false
          this.$emit('save', result.data)
        } catch (error) {
          this.showError({ error })
        } finally {
          this.saving = false
        }
      } else {
        this.showInvalidDataMessage()
      }
    },
  },
}
</script>

<style></style>
